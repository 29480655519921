#natural-events {
    padding: 20px;
    background-color: #f5f7fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width:100%;
    height:auto;
    font-family: 'Arial, sans-serif';

  }
#natural{
  width:60%
}

  /*----------------------------------------------Responsive---------------------------------*/
  @media (max-width: 992px) {
    #natural-events{
      width:100%;
    }
    #natural{
      margin-left:0;
      width:auto;
    }
  }

  @media (max-width: 768px) {
    #natural-events{
      min-width:90%;
    }
  }
  @media (max-width: 481px) {
    #natural-events{
      width:90%;
      margin-left:5%
    }

  }