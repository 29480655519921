#celestial-events {
  padding: 20px;
  background-color: #f5f7fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 62%;
  margin: 0 auto;
  height: auto;
  font-family: 'Arial, sans-serif';
  color: #000000;

}

#celestial-events h2 {
  text-align: center;
  color: #34495e;
  margin-bottom: 20px;
}

#celestial-events ul {
  list-style-type: none;
  padding: 0;
  margin: 20px 0 0 0;
  font-size: 1.5rem;

}

#celestial-events li {
  background: #ffffff;
  border-left: 4px solid #3498db;
  margin: 10px 0;
  padding: 15px 20px;
  border-radius: 4px;
  transition: background 0.3s ease;
}

#celestial-events li:hover {
  background: #ecf0f1;
}

#celestial-events li strong {
  color: #2980b9;
}

#celestial-events p {
  text-align: center;
  color: #7f8c8d;
}

/*----------------------------------------------Responsive---------------------------------*/
@media (max-width: 992px) {
  #celestial-events {
    width: 90%;
  }
}

@media (max-width: 769px) {
  #celestial-events {
    width: 90%;
  }

}

@media (max-width: 480px) {}