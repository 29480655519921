* {
    box-sizing: border-box;
    outline:none;
}

body {
    background-color: #F7F7F7;
    font-family: "Inconsolata", monospace;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.animation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}


#intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    width: 100%;
    
}

#weather-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    width: 100%;
    padding: 0;
    margin: 0;
}

.sub-box {
    background-color: #F7F7F7;
    border-radius: 25px;
    width: 100px;
    height: auto;
    text-align: center;
    justify-content: center;
}

.sub-box-title {
    color: #000000;
    margin-top: 5px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
}

.container-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 0;
}

.mini-container {
    flex: 1;
    height: 390px;
}

#meteo-title {
    margin-top: 30%;
    text-shadow: 2px 2px 2px #424242;

}


h1 {
    font-size: 4.5em;
    word-break: break-word;
}

.title {
    margin-top: -50px;
    color: #F7F7F7;
}

.meteo-title,
.meteo-subtitle {
    color: #F7F7F7;
    text-shadow: 1px 1px 1px #424242;
}
#max{
    margin-bottom:10%;
}
#place{
    margin-top:-10%;
}

.section-title{
    text-align: center;
}
.sub-title {
    color: #F7F7F7;
    margin-top: -10px;
}

#action-area {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 2%;
    width: 100%;
}

.btn-go {
    position: relative;
    display: inline-block;
    border: none;
    border-radius: 25px;
    opacity: 80%;
    font-family: "Inconsolata", monospace;
    font-size: 1rem;
    padding: 1rem 2rem;
    overflow: hidden;
    text-decoration: none;
    transition: color 0.4s, border-color 0.4s, transform 0.2s ease-in-out;
    z-index: 1;
    color: black;
}

.btn-go::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #47BFFF;
    border-radius: 3rem;
    transform: translateX(-100%);
    transition: transform 0.4s ease-in-out;
    z-index: -1;
}

.btn-go:hover::after {
    transform: translateX(0);
}

.btn-go:hover {
    color: black;
    border-color: #47BFFF;
    transform: scale(1.05);
}

/*-------------------------------------------Search location-----------------------------*/

.search-location-form {
    display: flex;
    margin-bottom:5%;
    width:50%;
  }
  
  #search {
    width: calc(100% - 60px);
    height: 50px;
    padding: 0 15px;
    border:3px solid #47BFFF;
    border-radius: 25px;
    box-sizing: border-box;
    outline: none;
    font-size: 1rem;
    margin-left:0;

  }
  
  .search-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    height:50px;
    margin-top: 0;
    padding: 18px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    background-color: #F7F7F7;
    color: #000000;
    border:3px solid #47BFFF;

  }
  .search-button:hover{
    background-color: #000000;
    color:#F7F7F7;
    border:3px solid #47BFFF;
  }
  
  .btn-sidebar {
    text-align: center;
    background-color: #82cbff;
    margin-top: 50px;
  }
/*------------------------------------------Meteo area boxes-------------------------------------------*/
#today-area{
    margin-top:-15%;
}
.today-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    border-radius: 25px;
    margin-top: 35px;
}

.charts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
    border-radius: 25px;
    padding: 20px;
    width: 90%;
    margin-left: 0;
    margin-top: 1%;
}

.charts {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20rem;
    height: 20rem;
    margin: 0 auto;
    margin-top:5%;
}

.light-chart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20rem;
    height: 20rem;
    margin: 0 auto;
}

.meteo-box-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 25px;
    border-radius: 25px;
    padding: 20px;
    width: 90%;
    margin-top: 25px;
    color: #000000;
}

.progress-bar {
    width: 100%;
    height: 20px;
    background-color: #f3f3f3;
    border-radius: 10px;
    margin-top: 15px;
    overflow: hidden;
}

.progress {
    height: 100%;
    background-color: #47BFFF;
    text-align: center;
    line-height: 20px;
    color: white;
    font-size: 12px;
    transition: width 0.3s ease;
}

.data-boxes {
    flex: 1 0 40%;
    padding: 20px;
    border-radius: 25px;
    text-align: center;
    box-sizing: border-box;
    font-size: 1.5em;
    background-color: #F7F7F7;
}

.MeteoCharts {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 10px;
}

#tempChart {
    display: block;
    visibility: hidden;
}

/*----------------------------------------------Responsive---------------------------------*/

@media (max-width: 1200px) {
    #meteo-title {
        width: 100%;
        text-align: left;
        margin: 0;
        font-size: 0.8rem;
        margin-left: 0;
        margin-top: 45%;
    }

    #max{
        margin-bottom:10%;
    }
    .search-location-form {
        margin-bottom:25%;
      }
    #weather-intro {
        padding: 10px;
        box-sizing: border-box;
    }

    .meteo-box-container {
        width: 98%;
        margin-left: 0;
    }

    .charts-container {
        width: 97%;
        margin-top: 2%;
        margin-left: 0;
    }

    .charts {
        width: 18rem;
        height: 18rem;
    }

    .light-chart {
        width: 18rem;
        height: 18rem;
    }
}

@media (max-width: 1024px) and (min-width:768) {
    h1 {
        margin-left: 25%;
    }
    
}

@media (max-width: 992px) {
    #meteo-title {
        text-align: left;
        margin: 0;
        font-size: 0.6rem;
        margin-top: 70%;
        margin-left:0;
    }

    #weather-intro {
        padding: 10px;
        box-sizing: border-box;
    }

    #max{
        margin-bottom:10%;
    }

    .search-location-form {
        margin-bottom:25%;
        width:70%;

      }

    .meteo-box-container {
        width: 97%;
    }

    .charts-container {
        width: 95%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        margin-top: 3%;
    }

    .charts {
        width: 15rem;
        height: 15rem;
    }

    .light-chart {
        width: 15rem;
        height: 15rem;
    }
}

@media (max-width: 769px) {
    #weather-intro {
        width: 100%;
    }

    .charts-container {
        flex-direction: column;
        align-items: center;
        width: 95%;
    }

    .charts {
        width: 300px;
        height: 300px;
    }

    .data-boxes {
        padding: 10px;
        font-size: 1em;
    }

    .meteo-box-container {
        width: 95%;
    }

    #max{
        margin-bottom:10%;
    }
    .search-location-form {
        margin-bottom:25%;
      }
    #meteo-title {
        margin-left: 0;
        font-size: 0.6rem;
        margin-top:75%;
    }
    .meteo-title{
        margin-top:-5%;
    }
    .search-location-form {
        width:70%;
        margin-bottom:35%;
      }


    .light-chart {
        width: 300px;
        height: 300px;
    }

    .MeteoCharts {
        padding: 5px;
    }

    .echarts-for-react {
        height: 15rem;
        width: 15rem;
    }
}

@media (max-width: 481px) {
    h1 {
        font-size: 3.5em;
    }

    .mini-container {
        height: auto;
    }

    .meteo-box-container,
    .charts-container {
        padding: 10px;
    }

    .charts {
        width: 100%;
        height: auto;
    }

    #meteo-title {
        margin-left: 0;
        margin-top: 75%;

    }

    .meteo-title {
        font-size: 3rem;
        margin-top: -10%;
    }

    .search-location-form {
        width:70%;
      }


    .data-boxes {
        font-size: 0.9em;
        padding: 5px;
    }

    .MeteoCharts {
        padding: 2px;
    }

    .echarts-for-react {
        height: 10rem;
        width: 10rem;
    }

    #tempChart {
        visibility: hidden;
        display: block;
    }

}

@media (max-width: 391px) {
    #meteo-title {
        margin-left: 0;
        margin-top: 65%;

    }
    .meteo-title{
        margin-top: -10%;
    }

    .meteo-title {
        font-size: 2.7rem;

    }

    .search-location-form {
        width:70%;
        margin-bottom:10%;
      }

    #max{
        margin-bottom:15%;
    }
    .meteo-subtitle {
        font-size: 1rem;
    }

    #tempChart {
        visibility: hidden;
        display: block;
    }

}

@media (max-width: 361px) {
    #meteo-title {
        margin-left: 0;
        margin-top: 70%;
    }

    .meteo-title {
        font-size: 2.5rem;
    }

    .meteo-subtitle {
        font-size: 1rem;
    }
    #place-subtitle{
        margin-top:-10%;
    }

    #max{
        margin-bottom:15%;
    }

    .search-location-form {
        width:70%;
      }

    #tempChart {
        display: block;
        visibility: hidden;
    }
}