body {
    font-family: "Inconsolata", monospace;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background-color: #000000;

}

.animation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.centered-text {
    text-align: center;
    margin: 0 auto;
    max-width: 800px;
}

.title {
    font-size: 6rem;
    margin-top: 15%;
}

#subtitle {
    font-size: 1.2em;
    line-height: 1.5em;
    margin: 10px 0;
    color: #F7F7F7;
}

#image-date {
    position: absolute;
    bottom: 65px;
    right: 65px;
    background: rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 5px;
    color: #F7F7F7;
    font-size: 1em;
}

#date-text {
    color: #F7F7F7;
}

#advanced-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    position: relative;
    text-align: center;
    overflow: auto;
    /* Permetti lo scrolling */
    z-index: 0;
    /* Assicurati che il background non interferisca con gli elementi sovrapposti */

}

#changeBackground {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    display: flex;
    position: relative;
    margin-left: 100px;

}

#to-universe {
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);

}

#to-earth {
    background-color: #08AEEA;
    background-image: linear-gradient(0deg, #08AEEA 0%, #2AF598 100%);

}

#central-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #7F7FD5;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    backdrop-filter: blur(6px);
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 25px;
    text-decoration: none;
    width: 150px;
    height: 50px;
    margin-top: 20px;
    position: relative;
}

#central-button a {
    color: white;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

#central-button:hover {
    background-color: #82cbff;

}

#central-button .arrow {
    width: 6px;
    height: 6px;
    border-right: 2px solid #C2FFE9;
    border-bottom: 2px solid #C2FFE9;
    transform: rotate(-45deg);
    margin-left: 6px;
    transition: transform 0.3s ease;
}

#central-button:hover .arrow {
    transform: rotate(-45deg) translate(4px, 4px);
}

#second-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    gap: 20px;
    height: 45%;
    background-color: #000000;
}

#third-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* Centra verticalmente */
    background-color: #000000;
    text-align: center;
    position: relative;
}

.data-title {
    font-size: 3rem;
    text-align: center;
    color: #F7F7F7;
}

.event-item {
    flex: 1;
    min-width: 300px;
}


#mars {
    width: 54%;
}

.text-no-data {
    color: #F7F7F7;
}

.image-date {
    position: absolute;
    right: 0;
    font-size: 14px;
    background-color: #000000;
    color: #F7F7F7;
    padding: 5px;
    border-radius: 5px;
}


/*----------------------------------------------Responsive---------------------------------*/
@media (max-width: 1200px) {
    .title {
        font-size: 6rem;
    }

    #advanced-title {
        text-align: center;
        margin-left: 5%;
    }

    .data-title {
        font-size: 2.5rem;
    }

    #timeline {
        width: 50%;
    }

    #mars {
        width: 60%;
    }


}

@media (max-width: 992px) {
    .title {
        font-size: 6rem;
    }

    #advanced-title {
        text-align: center;
        margin-left: 5%;
    }

    #earth {
        margin-left: 5%;
    }

    #subtitle {
        font-size: 0.9em;
    }

    .data-title {
        font-size: 2rem;
    }

    #subtitle {
        font-size: 1em;
    }

    #image-date {
        bottom: 30px;
        right: 30px;
        font-size: 0.9em;
    }


    #timeline,
    #mars,
    #objects {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .title {
        font-size: 5rem;
    }

    #advanced-title {
        text-align: center;
        margin-left: 5%;
    }

    #earth {
        margin-left: 5%;
    }

    #subtitle {
        font-size: 0.9em;
    }

    .data-title {
        font-size: 2rem;
    }

    #image-date {
        font-size: 0.8em;
    }

    #timeline,
    #mars,
    #objects {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .title {
        font-size: 2rem;
    }

    #subtitle {
        font-size: 0.8em;
    }

    .data-title {
        font-size: 1.5rem;
    }

    #image-date {
        font-size: 0.7em;
    }

    #advanced-intro {
        height: auto;
        padding: 20px;
    }

    .event-item {
        min-width: 100%;
        margin-bottom: 10px;
    }

    #timeline,
    #mars,
    #objects {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 3rem;
        text-align: center;
    }

    .data-title {
        text-align: center;
    }

    #timeline {
        display: none;
    }

    #earth {
        margin-left: 0;
    }

    #celestial {
        display: none;
    }

}