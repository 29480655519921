.sidebar {
  width: 60px;
  backdrop-filter: blur(16px) saturate(181%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  color: #F7F7F7;
  height: 100vh;
  padding-top: 80px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  overflow-y: auto;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  transform: translateX(-100%);
}

.sidebar-toggle {
  visibility: hidden;
}

/* Mostra la sidebar completamente aperta */
.sidebar.open {
  width: 350px;
  color: #f7f7f7;
  transform: translateX(0);
}

@media (min-width: 768px) {
  .sidebar {
    transform: translateX(0);
    /* Mostra la sidebar quando è aperta */
  }

  .sidebar:hover {
    width: 350px;
    transform: translateX(0);
  }
}

/*Voci del menu */
.sidebar-link {
  color: #000000;
  text-decoration: none;
  padding: 25px 0;
  margin: 10px 0;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  border-radius: 5px;
  margin-left: 15px;
  margin-right: 15px;
  white-space: nowrap;
}
.sidebar-link:hover{
  color:#000000;
}
/*Colore delle icone e svg */
.sidebar-link i,
.sidebar-link svg {
  color: #000000;
  font-size: 1.5rem;
  margin-right: 10px;
  vertical-align: middle;
}

/*----------------------------------------------Responsive---------------------------------*/
@media (max-width: 767px) {
  .sidebar {
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(243, 243, 243, 0.9);
    transition: transform 0.3s ease;
  }

  .sidebar.open {
    transform: translateX(0);
    width: 85%;
  }

  .sidebar-content {
    padding: 20px;
  }

  .sidebar-link {
    font-size: 1rem;
    padding: 15px 0;
  }


  .sidebar-link span {
    display: block;
    margin-left: 0;
  }

  .search-location-form {
    margin-top: 10px;
    padding-left: 0;
  }

  #search {
    width: calc(100% - 50px);
  }

  .search-button {
    margin-left: 0;
  }

  .sidebar-toggle {
    position: fixed;
    top: 20px;
    left: 20px;
    background: none;
    border: none;
    color: #ffffff;
    font-size: 2rem;
    cursor: pointer;
    z-index: 1100;
    visibility: visible;
  }

  .sidebar-toggle:hover {
    color: #000000;
  }
}

@media (min-width: 768px) {
  #toggle-area {
    visibility: hidden;
    margin-top: -20px
  }
}
