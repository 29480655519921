.loader {
    border: 8px solid #f3f3f3; 
    border-top: 8px solid #ff9425;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 1s linear infinite;
    top:45%;
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (max-width: 480px) {
    .loader{
        left:45%;
    }
}
