#slogan-container {
  position: absolute;
  left: 0;
  width: 50%;
  min-height: 100vh;
  background-image: url("../img/signupPageIntro.png");
  background-repeat: no-repeat;
  background-position: cover;
  background-size: 100%;

}

#go-home {
  border-radius: 25px;
  position: absolute;
  left: 10%;
  top: 3%;
  font-size: 1.2rem;
  font-weight: bold;
  background: transparent;
  color: #000000;
  border: 1px solid #000000;

}

#back {
  color: #000000;
  font-size: 1rem;
}

#form-container {
  right: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 50%;
  background-color: #f0f0f0;
}

#form-box {
  background: white;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.title-signup {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.input-field {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.submit-btn {
  width: 100%;
  padding: 12px;
  background: rgb(31, 55, 107);
  background: linear-gradient(90deg, rgba(31, 55, 107, 1) 0%, rgba(200, 130, 255, 1) 60%, rgba(130, 203, 255, 1) 100%);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 20px;
}

.submit-btn:hover {
  background-color: #000000;
}

.social-login p {
  margin-bottom: 10px;
}

.google-btn {
  background-color: rgba(200, 130, 255, 1);
  color: white;
  padding: 12px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 43%;
}

.google-btn:hover {
  background-color: #7890fa;
}

.toggle-text {
  margin-top: 20px;
  color: #666;
}

.toggle-link {
  color: rgba(200, 130, 255, 1);
  cursor: pointer;
}

.toggle-link:hover {
  text-decoration: underline;
}

/*Tablet */
@media (max-width: 1025px) {
  #slogan-container {
    width: 100%;
    height: 40vh;
    background-position: center;
    background-repeat: no-repeat;
  }

  #form-container {
    width: 100%;
    padding: 20px;
    background-color: #f0f0f0;
  }

  #form-box {
    max-width: 450px;
    padding: 30px;
  }

  #google-btn {
    margin-left: 30%;
  }
}

/*Mobile */
@media (max-width: 768px) {
  #slogan-container {
    width: 100%;
    height: 30vh;
    background-position: center;
    background-size: cover;
  }

  #form-container {
    position: relative;
    width: 100%;
    padding: 20px;
  }

  #form-box {
    max-width: 100%;
    padding: 20px;
    box-shadow: none;
  }

  .google-btn {
    margin-left: 45%;
    padding: 10px;
  }

  .title-signup {
    font-size: 20px;
  }

  .submit-btn {
    padding: 10px;
  }

  .input-field {
    padding: 10px;
    font-size: 14px;
  }

  .toggle-text {
    margin-top: 10px;
  }

  #go-home {
    font-size: 1rem;
    left: 5%;
    top: 5%;
  }
}

@media (max-width: 481px) {
  .google-btn {
    margin-left: 44%;
  }

}