#reset-box {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.35);
    position: absolute;
    top: 35%;
    left: 40%;
    overflow: hidden;
    width: auto;
    max-width: 100%;
    height: auto;
    text-align: center;
}

#reset-box p {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin: 20px 0;
}

#reset-box span {
    font-size: 12px;
}

#reset-box h5 {
    color: #333;
    font-size: 13px;
    text-decoration: none;
    margin: 15px 0 10px;
}


#reset-box button {
    background: rgb(31, 55, 107);
    background: linear-gradient(90deg, rgba(31, 55, 107, 1) 0%, rgba(200, 130, 255, 1) 60%, rgba(130, 203, 255, 1) 100%);
    color: #F7F7F7;
    font-size: 12px;
    padding: 10px 45px;
    border-radius: 8px;
    border:none;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 10px;
    cursor: pointer;
}


#return {
    background-color: white;
}

#ret {
    color: #000000;
    
}
#reset-box button:hidden {
    background-color: transparent;
}

#reset-box form {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    height: 100%;
}

#reset-box input {
    background-color: #eee;
    border: none;
    margin: 10px 0;
    padding: 12px 15px;
    font-size: 14px;
    border-radius: 8px;
    width: 100%;
    outline: none;
    margin-top: -10%;
}

@media (max-width: 480px) {
    #reset-box {
        width: 90%;
        left: 5%;
    }

}

@media (max-width: 1025px) {
    #reset-box {
        width: 50%;
        top: 40%;
        left: 27%;
    }
}

@media (max-width: 992px) {
    #reset-box {
        width: 40%;
        left: 30%;
        top: 40%;
    }
}

@media (max-width: 769px) {
    #reset-box {
        width: 40%;
        left: 30%;
    }
}

@media (max-width: 541px) {
    #reset-box {
        width: 50%;
        top: 30%;
        left: 27%;
    }
}

@media (max-width: 413px) {
    #reset-box {
        width: 80%;
        top: 35%;
        left: 10%;
    }
}

@media (max-width: 381px) {
    #reset-box {
        width: 80%;
        top: 32%;
        left: 10%;
    }
}