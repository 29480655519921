.forecast-container {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 5%;
}


.forecast-table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 25px;
    overflow: hidden;
    backdrop-filter: blur(4px);
    background-color: #F7F7F7;
}

.forecast-table th,
.forecast-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    word-break: break-word;
}

.forecast-table th {
    background-color: #F7F7F7;
    font-weight: bold;
    color: #000000;
}

.forecast-table td {
    background-color: #F7F7F7;
}

.forecast-table .weatherIcon {
    font-size: 2rem;
    margin-bottom: 10px;
}

.forecast-table .temp {
    font-size: 1.2rem;
    color: #000000;
    margin-top: 5px;
}

.today-forecast-container {
    margin-bottom: 20px;
    width:100%;
    max-width: 1200px;
    min-width:300px;
    padding:20px;
    margin:0 auto;
    margin-top: 15%;
}


/*Today forecasts*/
.today-forecast {
    display: flex;
    justify-content:flex-start; /*l'uno accanto l'altro*/
    flex-wrap: wrap;
    width:100%;
    overflow-x:auto; /*scroll se troppo grande*/
    
   
}
.today-forecast-container h2{
    color:#000000;
}
.today-forecast-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: none;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #FFFFFF44;
}

.forecast-time {
    margin-right: 10px;
    font-weight: bold;
}

.forecast-temp {
    margin-right: 10px;
    font-size: 1.2rem;
    color: #000000;
}
p{
    color:#000000;
}
.info{
    font-size:1.5rem;
}

/*GRADIENT WEATHER LABELS*/
.weather-labels {
    margin-top: 10px;
    width:75%;
    display:flex;
    justify-content: flex-start; /*a sx*/
    flex-wrap: wrap; /*permette di andare a capo*/
    color:#000000;
    border-color: 2px solid #000000;
    background-color: #F7F7F7;
    border-radius: 10px;
}

.weather-labels-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin:0;
}

.weather-label-item {
    margin-right: 10px;
    margin:5px;
    display: flex;
    align-items: left;
    
    
}

.weather-label-color {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    display: inline-block;
    border-radius: 50%;
    
}

/*----------------------------------------------Responsive---------------------------------*/
@media (max-width: 768px) {
    .today-forecast-item {
        flex-basis: 100%;
        margin-right: 0;
    }
    .today-forecast-container{
        width:95%;
        padding:15px;
    }
    .weather-labels{
        width:100%;

    }
    .weather-label-item{
        margin-right: 5px;
        width:auto;
        text-align: center;
    }
    .weather-labels-list{
        flex-direction: column;
        width:100%;
    }
}


@media (max-width: 1200px) {
    .forecast-container {
        width: 90%;
        margin-left: 5.5%;
    }

    .forecast-table th,
    .forecast-table td {
        padding: 8px;
    }

    .forecast-table .weatherIcon {
        font-size: 1.5rem;
    }

    .forecast-table .temp {
        font-size: 1rem;
    }
}

@media (max-width: 992px) {
    .forecast-container {
        width: 80%;
        margin-left: 10%;
    }

    .forecast-table th,
    .forecast-table td {
        padding: 6px;
    }

    .forecast-table .weatherIcon {
        font-size: 1.2rem;
    }

    .forecast-table .temp {
        font-size: 0.9rem;
    }
}

@media (max-width: 768px) {
    .forecast-container {
        width: 95%;
        margin-left: 2.5%;
    }

    .forecast-table th,
    .forecast-table td {
        padding: 5px;
    }

    .forecast-table .weatherIcon {
        font-size: 1rem;
    }

    .forecast-table .temp {
        font-size: 0.8rem;
    }
}

@media (max-width: 576px) {
    .forecast-container {
        width: 100%;
        margin-left: 0;
        padding: 15px;
    }

    .forecast-table th,
    .forecast-table td {
        padding: 4px;
    }

    .forecast-table .weatherIcon {
        font-size: 0.9rem;
    }

    .forecast-table .temp {
        font-size: 0.7rem;
    }
    .today-forecast-container{
        width:100%;
        padding:10px;
    }
    .chart-container {
        width: 100%; 
        max-width: 1200px; 
        margin: 0 auto; 
    }
}

@media (max-width: 481px) {
    .forecast-container {
        padding: 10px;
    }
    .forecast-table{
        width:100%;
    }
    .forecast-table th,
    .forecast-table td {
        padding: 3px;
        width:10%;
    }

    .forecast-table .weatherIcon {
        font-size: 0.8rem;
    }

    .forecast-table .temp {
        font-size: 0.6rem;
    }
    .weather-labels {
        margin-top:150px;
    }
}


@media (max-width: 391px) {
    .forecast-container {
        padding: 10px;
        width:70%;
    }
    .today-forecast-item{
        box-shadow:none;
        
    }
    .info{
        font-size: 0.9rem;
    }
    .hour{
        font-size:0.8rem;
    }
    .info-day{
        font-size:2rem;
    }
    .info-temp{
        font-size:0.7rem;
    }
    .meteo-table{
        width:100%;
    }
}