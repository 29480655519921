.neo-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}
#objects {
  width: 60%;
}
.neo-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color:#F7F7F7;
}

.neo-item {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  height:auto;
  min-height:300px;
}

.neo-item:hover {
  transform: scale(1.05);
}

.neo-header {
  margin-bottom: 10px;
}

.neo-details p {
  margin: 5px 0;
}

/*frecce */
.custom-arrow {
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custom-arrow-next {
  right: 5px; 
  top:60%;
}

.custom-arrow-prev {
  left: 5px; 
  top:60%;

}

.custom-arrow::before {
  font-size: 20px;
  color: #000000; 
}

.custom-arrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/*----------------------------------------------Responsive---------------------------------*/
@media (max-width:1200px){
  #objects {
    width: 40%;
}
}
@media (max-width: 1024px) {
  .neo-title {
    font-size: 1.75rem;
  }

  .neo-item {
    padding: 12px;
  }
}

@media (max-width: 768px) {
  .neo-title {
    font-size: 1.5rem;
  }

  .neo-item {
    padding: 10px;
  }
  #objects {
    width: 90%;

  }
}

@media (max-width: 480px) {
  .neo-container {
    width:100%;
    margin-left:0;

  }
  .neo-title {
    font-size: 1.25rem;

  }

  .neo-item {
    padding: 60px;
  }
  #objects{
    width:90%;

  }

  .neo-details p {
    font-size: 0.9rem;
  }
}
