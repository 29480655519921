#mars-area{
    padding: 20px;
    background-color: #f5f7fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width:80%;
    height:auto;
    margin: auto;
    font-family: 'Arial, sans-serif';
  }
.photo-slide {
    position: relative;
    text-align: center;
    background: #f7f7f7;
    padding: 10px;
    height:auto;
  }
  
  .photo-slide img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .photo-info {
    margin-top: 10px;
  }
  
  .photo-info p {
    margin: 5px 0;
    color: #333;
  }
  
  .slick-dots {
    bottom: 10px;
  }
  
  .slick-prev, .slick-next {
    z-index: 1;
    width:40px;
    height:40px;
    z-index:1;
  }
  
  .slick-prev {
    left: 10px;
  }
  
  .slick-next {
    right: 10px;
  }
  .slick-prev:before, .slick-next:before{
    font-size:20px;
    color:#000000;
  }
  .slick-prev:hover, .slick-next:hover{
    background:#333;
  }
  .loading{
    color:#000000;
    margin-left:5%;
    font-size:1.5rem;
  }
  
  /*----------------------------------------------Responsive---------------------------------*/
  @media (max-width: 992px) {
    #mars-area{
      width:85%;
      margin-left: 11.5%;
    }
  }

  @media (max-width: 769px) {
    #mars-area{
      width:85%;
      margin-left: 11.5%;
    }
  }
  @media (max-width: 480px) {
    #mars-area{
      width:90%;
      margin-left: 5%;
      height:auto;
    }
    .photo-slide {
      height:100px;
    }
  }