#lunar_phases{
    background-color: #000000;

}
.info-moon{
    color:white;
}

.lunar-phase {
    padding: 20px;
    background-color: #000000; 
}

.info-container {
    display: flex;
    align-items: center; 
    border-radius: 25px; 
    padding: 15px; 
    margin: 10px 0; 
}

.info-container p {
    margin-left: 10px; 
    color:white;

}
.line{
    width:30%;
}
