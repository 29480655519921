/*------------------------------------------Favorite places area-----------------------------------------*/
.user-places-container{
    width:95%;
    border-radius:25px;
    margin-top: 5%;
    margin-left:5%;
    
}
.user-places-container h2{
    color:#F7F7F7;
    font-size:0.1rem;
}
.user-places-container ul {
    list-style-type: none;
    padding: 0;
    margin-left: 4%;

}
#loc-area{
    border-radius:25px;
    margin-bottom:25px; 
}
#fav-text{
    font-size:2rem;
}
#fav-insert{
    margin-left:0;
    border-radius: 25px;
    border:none;
    height: 35px;
    width:200px;
    margin-left: 5%;

}

.user-places-container li {
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    padding: 5px 0;
    
}

.user-places-container button {
    padding: 5px 10px; 
    border:none; 
    border-radius: 25px;
    cursor: pointer; 
    margin-left:5px;
    width: 90%;
    background-color: transparent;
    
    
}
#location{
    font-size:1.2rem;
    border-radius:25px;
    color:#000000;
}


#add-loc{
    background-color: rgba(31, 55, 107, 1);
    color:#F7F7F7;
    margin-left:5%;
    width:40px;
}

#btn-del:hover{
    color:#F7F7F7;

}

#add-loc:hover{
    background-color: #000000;
    color: #F7F7F7;
}


/*----------------------------------------------Responsive---------------------------------*/
@media (max-width: 992px) {
    #loc-area{
        margin-bottom:25%;
    }
}
@media (max-width: 768px) {
    .user-places-container{
        width:90%;
        border-radius:25px;
    }
    .user-places-container ul {
        margin-left:5px;
    }
    .user-places-container li {
        width:100%;
    
    }
    #loc-area{
        width:95%;
        margin-left:1%;
    }

    #fav-insert{
        width:70%;
        font-size: 1rem;
    }
}

@media (max-width: 576px) {
    .user-places-container{
        margin-left:5%;
    }
}

@media (max-width: 481px) {
    #loc{
        margin-bottom: 5%;
    }
    #fav-insert{
        width:55%;
    }
    .new-loc{
        margin-left:5%;
        width:35%;
    }
    .user-places-container{
        margin-left:0;
    }
}