@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


html,
body {
  margin: 0;
  height: 100%;
  font-family: "Inconsolata", monospace;
  overflow-x: hidden;
  background-color: #F5F5F5;
}

#root,
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

#intro {
  display: flex;
  align-items: center;
  height: 90vh;
  padding: 0 20px;
  position: relative;
  background-position: absolute;
  background-color: #F5F5F5;
}

.gradient-background {
  background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
  width: 100%;
  height: 100vh;
}

.gradient-fallback {
  background: linear-gradient(135deg, #f093fb 0%, #f5576c 100%);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.container {
  display: flex;
  width: 100%;
  align-items: center;
}

#title-logo-container {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#title-area {
  flex: 1;
  text-align: center;
  margin: 0 auto;
  padding: 0 10%;
  margin-top: 15%;
}

#intro-title {
  font-size: 7rem;
}

a {
  text-decoration: none;
  color: #F7F7F7;

}

a:hover {
  color: #F7F7F7;
}

#intro-subtitle {
  font-size: 2rem;
}

#title {
  font-size: 4rem;
  color: #F7F7F7;
  margin: 0;
}

#discover-btn-area {
  background-color: #F5F5F5;
  margin-top: 0;
}


.button-area {
  display: flex;
  justify-content: center;
  height: 12vh;
  border: none;
  background-color: #F5F5F5;
}

#discover {
  font-size: 1.2rem;
}

.arrow-button {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);

  transition: background-color 0.3s ease;
  color: #F7F7F7;
  text-decoration: none;
}

.arrow-button:hover {
  background-color: #000000;
  color: #F7F7F7;
}

.arrow {
  width: 6px;
  height: 6px;
  border-right: 2px solid #C2FFE9;
  border-bottom: 2px solid #C2FFE9;
  transform: rotate(-45deg);
  margin-left: 6px;
  transition: transform 0.3s ease;

}

.arrow-button:hover .arrow {
  transform: rotate(-45deg) translate(4px, 4px);
}

/*-----------------------------------------------features---------------------------------------------*/
#about-area {
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
}

#features-title {
  font-size: 4rem;
  color: #000000;
  margin-top: 10%;
  text-align: center;
}

#features-subtitle {
  font-size: 1.5rem;
  color: #000000;
  margin-top: -35px;
  text-align: center;
}

.info-box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px; 
  margin-top: 3%; 
  margin-bottom: 3%; 
  width: 76%;
  margin-left: auto;
  margin-right: auto;
}

.info-box {
  padding: 5px; 
  background-color: #33407c;
  border-radius: 50px;
}

.info-box p {
  margin-left: 5%; 
  margin-right: 5%; 
  margin-top: 5%; 
  margin-bottom: 5%; 
  color:#F7F7F7;
  font-size: 1.2rem;

}

.info-box-test {
  text-align: center;
  color: #F7F7F7;

}

.info-box-large p {
  font-size: 1.8rem;
}
.info-box-large {
  flex: 1;
  min-height: 300px;
  margin-left: 2%; 
}

.info-box-small p {
  font-size: 1.3rem;
}

.info-box-small {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  min-height: 300px;
  margin-left: 1%; 
}

.info-box img {
  max-width: 60%;
  height: auto;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.mini-container.right {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.info-box h1, .info-box h2 {
  font-size: 2.5rem;
  margin-top: 10%;
}

.logo {
  height: 50%;
}


/*----------------------------------------Call to action area-----------------------------------------------*/
#call-to-action-area {
  background: #7F7FD5;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  border-radius: 50px;
  width: 75%;
}

#signup-area {
  margin-top: 50px;
}

#signup-area h2 a {
  background-color: white;
  color: #000;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;
}

#signup-area h2 a:hover {
  background-color: #000000;
  color: #F7F7F7;
}

#call-to-action-text {
  font-size: 3rem;
  color:#F7F7F7;
}

#call-to-action-text2 {
  margin-left: 15%;
  margin-right: 15%;
  font-size: 1.5rem;
  color:#F7F7F7;

}


/*----------------------------------------------Clouds animation----------------------------------------*/
.weather-container {
  position: relative;
  overflow: hidden;


}

.weather-animation {
  position: relative;
  width: 100vw;
  height: 100vh;
  pointer-events: none;   /* Permette gli eventi del mouse e del touch di passare attraverso il canvas */
}

.weather-animation .canvas-container {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 90vh;
  pointer-events: auto;   /* Abilita gli eventi del mouse e del touch per il canvas */
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bold;
  z-index: 1;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.centered-text h2{
  color:#F7F7F7;
}

canvas {
  width: 100%;
  height: 100%;
}

#intro {
  position: relative;
  display: flex;
  align-items: center;
  height: 96vh;
  padding: 0 20px;
}


/*----------------------------------------------Responsive---------------------------------*/
@media (max-width: 1024px) {
  .info-box-container {
    flex-direction: column;
    width: 70%;
  }

  .info-box-large,
  .info-box-small {
    flex: none;
    width: 100%;
  }

  #features-title {
    font-size: 3rem;
  }

  #features-subtitle {
    font-size: 1.2rem;
  } 

  #intro-title {
    font-size: 5rem;
  }

}

@media (max-width: 768px) {
  .info-box-container{
    flex-direction: column;
    width:100%;
  }

  .info-box{
    width:100%;
    font-size:1rem;
  }
  .info-box h1, .info-box h2{
    font-size:1.5rem;
  }
  #title-area {
    padding: 0 5%;
  }

  #intro-title {
    font-size: 2rem;
  }

  .arrow-button {
    width: 120px;
    height: 40px;
    font-size: 1.2rem;
  }

  #features-title {
    font-size: 2.5rem;
  }

}

@media only screen and (max-width: 600px) {
  #intro {
    height: 100vh;
  }

  #intro-title {
    font-size: 3rem;
  }

  .info-box{
    padding:10px;
    font-size:0.9rem;
  }

  #feauters-title{
    font-size:2rem;
  }
  .info-box-container{
    width:80%;
    gap:10px;
  }
  .cloud-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .canvas {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 480px) {
  #intro-title {
    font-size: 3rem;
    text-align: left;
  }

  .info-box-container{
    width:95%;
    gap:10px;
  }
  .weather-animation .canvas-container {
    height: 100vh;
  }

  #intro {
    height: 80vh;
  }

  #dsc-btn {
    margin-top: 10%;
  }

  .btn arrow-button {
    border-radius: 25px;

  }

  #discover-btn-area {
    height: 15vh;
  }

  .arrow-button {
    width: 100px;
    height: 35px;
    font-size: 1rem;
  }

  #features-title {
    font-size: 3rem;
  }

  #features-subtitle {
    margin-top: -25px;
    font-size: 1rem;
  }

  .info-box h1 {
    font-size: 1.5rem;
    margin: 0;
    text-align: center;
    margin-top: 15%;

  }

  .info-box h2 {
    font-size: 1.5rem;
    margin-top: 15%;
  }

  .info-box p {

    font-size: 1rem;
  }

  .info-box p {
    font-size: 0.8
  }

  #call-to-action-area {
    width: 100%;
    margin-top: 25%;
  }
}