body{
    background-color: #F7F7F7;
    color:#000000;
}
#users-data{
    width:80%;
    border-radius:25px;
    margin-left:10%;
    margin-top: 5%;;
}
#user-area{
    flex-direction: row;
    justify-content: center;
}
.user-info{
    border-radius:25px;
    height:25px;
    color:#000000;
    margin-left:15px;
}
#label2,#label3{
    margin-left:100px;
}
#logout-button{
    background-color:red;
    border-radius: 25px;
    color:#F7F7F7;
    width:100px;
    height:50px;
    margin-top:5%;
}

/*----------------------------------------------Responsive---------------------------------*/
@media (max-width: 768px) {
    #label2,#label3{
        margin-left:20px;
    }
    #users-data{
        width:90%;
        margin-left:5.5%;
    }
    #user-area{
        margin-left:5%;
    }
    h1{
        font-size:5rem;
    }
    
    #loc-area{
        margin-left:8%;
    }
}

@media (max-width: 576px) {
    #label2{
        margin-left:30px;
    }
    #users-data{
        width:90%;
        margin-left:5.5%;
    }
    #user-area{
        margin-left:5%;
    }
    h1{
        font-size:5rem;
    }
   
    #loc-area{
        margin-left:8%;
    }
}


@media (max-width: 481px) {
    #label2{
        margin-left:30px;
    }
    #users-data{
        width:90%;
        margin-left:5.5%;
    }
    #user-area{
        margin-left:5%;
    }
    h1{
        font-size:3rem;
    }
  
}